import { bodySmallStyles, heading2Styles, heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { css, html, LitElement, nothing } from 'lit';
import { navigator as nav } from 'lit-element-router';
import { repeat } from 'lit/directives/repeat.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import '@brightspace-ui/core/components/alert/alert.js';
import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/inputs/input-text.js';
import '@brightspace-ui/core/components/form/form.js';
import '@brightspace-ui/core/components/inputs/input-checkbox.js';
import '@brightspace-ui/core/components/inputs/input-checkbox-spacer.js';
import '@brightspace-ui/core/components/status-indicator/status-indicator.js';

import { LocalizeNova } from '../../../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaPermissionMixin } from '../../../../../shared/mixins/nova-permission-mixin/nova-permission-mixin.js';

export default class ManageUserPermissions extends NovaPermissionMixin(LocalizeNova(RequesterMixin(SkeletonMixin(nav(LitElement))))) {
  static get properties() {
    return {
      // Add properties here
      user: { type: Object, attribute: false },
      allRoles: { type: Array, attribute: false },
      _roles: { type: Array, attribute: false },
    };
  }

  static get styles() {
    return [
      super.styles,
      bodySmallStyles,
      heading2Styles,
      heading3Styles,
      inputLabelStyles,
      css`
        .checkbox-wrapper {
          padding-bottom: 12px;
        }

        .input-wrapper {
          padding-bottom: 24px;
          width: 400px;
        }

        .name-container {
          align-items: center;
          display: flex;
          flex-direction: row;
        }

        .name-container d2l-status-indicator {
          margin-left: 12px;
        }

        .no-roles-alert {
          margin-bottom: 12px;
        }

        .update-permissions-heading {
          margin-bottom: 12px;
          margin-top: 12px;
        }

        .update-permissions-content {
          margin-bottom: 24px;
          margin-top: 0;
        }

        d2l-form {
          padding-bottom: 12px;
        }

        d2l-switch {
          padding-bottom: 12px;;
        }

        d2l-input-checkbox {
          margin-bottom: 0;
        }

        h3.d2l-heading-3 {
          margin-top: 0;
        }
      `];
  }

  constructor() {
    super();
    this.user = null;
    this.allRoles = [];
    this._roles = [];
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
    this.updatePermissions = ['admin:userpermissions:update'];
    this.viewPermissions = ['admin:userpermissions:view'];
  }

  async firstUpdated() {
    super.firstUpdated();
    this.skeleton = true;
    this._roles = this.user?.roles?.map(role => role.roleId) ?? [];
    this.skeleton = false;
  }

  render() {
    return html`
      <div>
        <h2 class="d2l-heading-2 update-permissions-heading">${this.localize('manage-user-permissions.heading')}</h2>
        <p class="update-permissions-content">${this.localize('manage-user-permissions.pageDescription')}</p>
        <div class="d2l-skeletize">
          <d2l-form id="form" @d2l-form-submit="${this.validate}" @change="${this._updateSelectedRoles}">
            <div class="name-container">
              <div class="input-wrapper">
                <label for="name" class="d2l-input-label d2l-input-label-required">${this.localize('manage-user-permissions.userNameInputLabel')}</label>
                <d2l-input-text
                  id="name"
                  disabled
                  label-hidden
                  label="${this.localize('manage-user-permissions.userNameInputLabel')}"
                  value="${this.user?.firstName} ${this.user?.lastName}"
                  required
                  >
                </d2l-input-text>
              </div>
              ${this.user?.active ? html `<d2l-status-indicator state="default" text="${this.localize('manage-user-permissions.statusIndicatorActive')}"></d2l-status-indicator>` : nothing}
            </div>
            <div class="input-wrapper">
              <label for="email" class="d2l-input-label d2l-input-label-required">${this.localize('manage-user-permissions.emailInputLabel')}</label>
              <d2l-input-text
                id="email"
                disabled
                label-hidden
                label="${this.localize('manage-user-permissions.emailInputLabel')}"
                value="${this.user?.email}"
                required
                ></d2l-input-text>
            </div>
            <div class="input-wrapper">
              <label for="title" class="d2l-input-label d2l-input-label-required">${this.localize('manage-user-permissions.titleInputLabel')}</label>
              <d2l-input-text
                id="title"
                disabled
                label-hidden
                label="${this.localize('manage-user-permissions.titleInputLabel')}"
                value="${this.user?.title}"
                required
                ></d2l-input-text>
            </div>
            <h3 class="d2l-heading-3">${this.localize('manage-user-permissions.rolesHeading')}</h3>
            ${repeat(this.allRoles, role => role.roleId, role => html`
              <div class="checkbox-wrapper">
                <d2l-input-checkbox
                id="${role.roleId}"
                ?checked="${this.user?.roles?.some(userRole => userRole.roleId === role.roleId)}"
                >
                  ${role.roleName}
                </d2l-input-checkbox>
                <d2l-input-checkbox-spacer class="d2l-body-small">
                  ${role.description}
                </d2l-input-checkbox-spacer>
              </div>
            `)}
            ${!this._roles.length ? html `<d2l-alert type="warning" class="no-roles-alert">${this.localize('manage-user-permissions.noRolesAlert')}</d2l-alert>` : nothing}
            <d2l-button @click="${this._submitAndUpdateRoles}" type="submit" primary>${this.localize('manage-user-permissions.updateUserButton')}</d2l-button>
            <d2l-button @click="${this._handleCancelClick}" type="cancel" >${this.localize('general.button-text.cancel')}</d2l-button>
          </d2l-form>
        </div>
      </div>
    `;
  }

  _updateSelectedRoles(e) {
    if (e.target.checked) {
      if (!this._roles.includes(e.target.id)) this._roles.push(e.target.id);
      this.requestUpdate();
    } else {
      this._roles = this._roles.filter(role => role !== e.target.id);
    }
  }

  async _submitAndUpdateRoles() {
    try {
      await this.client.bulkUpdateRoleUserAssignments(this.user.guid, this._roles);
      this.skeleton = true;
      const admins = await this.client.getAllUsersByTenantId(this.session._tenant.id, { includeRoles: true });
      this.dispatchEvent(new CustomEvent('nova-admin-state-change', {
        detail: { admins, action: 'edit-permissions' },
        bubbles: true,
        composed: true,
      }));
      this.session.toast({ type: 'default', message: this.localize('manage-user-permissions.successToast') });
    } catch (error) {
      if (error.status !== 405) {
        this.session.toast({ type: 'critical', message: this.localize('manage-user-permissions.failToast') });
      }
      this.skeleton = false;
    }
  }

  _handleCancelClick() {
    this.dispatchEvent(new CustomEvent('nova-admin-state-change', {
      detail: { action: 'cancel' },
      bubbles: true,
      composed: true,
    }));
  }
}

window.customElements.define('manage-user-permissions', ManageUserPermissions);

