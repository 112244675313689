import '@brightspace-ui/core/components/button/button.js';
import '../../../../components/permissions-table/permissions-table.js';
import '../manage-user-permissions/manage-user-permissions.js';

import { css, html, LitElement, nothing } from 'lit';
import { heading2Styles } from '@brightspace-ui/core/components/typography/styles.js';

import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { LocalizeNova } from '../../../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaAsyncInitMixin } from '../../../../../shared/mixins/nova-async-init-mixin/nova-async-init-mixin.js';
import { NovaPermissionMixin } from '../../../../../shared/mixins/nova-permission-mixin/nova-permission-mixin.js';

import '../../../../../shared/components/dialog/confirmation-dialog/confirmation-dialog.js';

class EditPermissions extends NovaAsyncInitMixin(NovaPermissionMixin(LocalizeNova(RequesterMixin(SkeletonMixin(LitElement))))) {
  static get properties() {
    return {
      admins: { type: Array, attribute: false },
      allRoles: { type: Array, attribute: false },
      tenantId: { type: String, attribute: false },
      _deleteDialogOpen: { type: Boolean, attribute: false },
      _selectedAdmin: { type: Object, attribute: false },
      _activeTemplate: { type: String, attribute: false },
    };
  }

  static get styles() {
    return [
      super.styles,
      heading2Styles,
      css`
      .permissions-heading {
        margin-bottom: 12px;
      }

      .permissions-content {
        margin-bottom: 24px;
        margin-top: 0;
      }
  `];
  }

  constructor() {
    super();
    this.admins = [];
    this.allRoles = [];
    this.tenantId = '';
    this._selectedAdmin = null;
    this._deleteDialogOpen = false;
    this._activeTemplate = 'edit-permissions';
    this._handleBackToEditPermissions = this._handleBackToEditPermissions.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
  }

  async loadData() {
    this.activeTemplate = 'edit-permissions';
    this.skeleton = true;
    this.tenantId = this.session._tenant.id;
    this.allRoles = await this.client.getRolesByTenantId(this.tenantId);
    this.admins = await this.client.getAllUsersByTenantId(this.tenantId, { includeRoles: true });
    this.skeleton = false;
  }

  render() {
    return html`
      ${this._permissionsTableTemplate}
      ${this._manageUserPermissionsTemplate}
    `;
  }

  get activeTemplate() {
    return this._activeTemplate;
  }

  set activeTemplate(value) {
    // get the current path
    this._activeTemplate = value;
    const breadcrumbTemplate = value === 'manage-user-permissions' ?
      html`<d2l-breadcrumb text="Users" href="${window.location.pathname}${window.location.hash}" @click="${this._handleBackToEditPermissions}"></d2l-breadcrumb>` : nothing;
    this.dispatchEvent(new CustomEvent('change-breadcrumb', { detail: { breadcrumbTemplate } }));
  }

  async _handleStateChange(e) {
    const { admin, action } = e.detail;
    this._selectedAdmin = { ...admin };
    if (action === 'toggle-active-status') {
      this._deleteDialogOpen = true;
    } else if (action === 'manage-user-permissions') {
      this.activeTemplate = 'manage-user-permissions';
    } else if (action === 'edit-permissions') {
      this.admins = e.detail.admins;
      this.activeTemplate = 'edit-permissions';
    } else if (action === 'cancel') {
      this.activeTemplate = 'edit-permissions';
    }
  }

  _handleBackToEditPermissions() {
    this.activeTemplate = 'edit-permissions';
  }

  get _permissionsTableTemplate() {
    if (this._activeTemplate !== 'edit-permissions') return nothing;
    return html`
      <h2 class="d2l-heading-2 permissions-heading">${this.localize('edit-permissions.userPermissions')}</h2>
      <p class="permissions-content">${this.localize('edit-permissions.userPermissions.description')}</p>
      <permissions-table class="d2l-skeletize" @nova-admin-state-change="${this._handleStateChange}" .allRoles=${this.allRoles} .admins=${this.admins} .tenantId="${this.tenantId}"></permissions-table>
      ${this._confirmationDialogTemplate}
    `;
  }

  get _manageUserPermissionsTemplate() {
    if (this.activeTemplate !== 'manage-user-permissions') return nothing;
    return html`
      <manage-user-permissions @nova-admin-state-change="${this._handleStateChange}" .user=${this._selectedAdmin} .allRoles=${this.allRoles}></manage-user-permissions>
    `;
  }

  get _confirmationDialogTemplate() {
    return html`
      <confirmation-dialog
        ?opened=${this._deleteDialogOpen}
        type="toggleUserActiveStatusConfirmation"
        .data="${this._selectedAdmin}"
        @d2l-dialog-close=${this._dialogClose}>
      </confirmation-dialog>`;
  }

  async _dialogClose(e) {
    // TODO: actually update the user active status and roles here
    const { action } = e.detail;
    this._deleteDialogOpen = false;

    if (action === 'toggleActiveStatus') {
      this._selectedAdmin.active = !this._selectedAdmin.active;
      try {
        await this.client.toggleUserActiveStatus(this._selectedAdmin);
        const message = this._selectedAdmin?.active ? this.localize('edit-permissions.permissions-table.activateUserRolesConfirmation.toast') : this.localize('edit-permissions.permissions-table.deactivateUserRolesConfirmation.toast');
        this.session.toast({ type: 'default', message });
        this.admins = this.admins.map(admin => (admin.guid === this._selectedAdmin.guid ? this._selectedAdmin : admin));
      } catch (error) {
        if (error.status !== 405) {
          const message = this._selectedAdmin?.active ? this.localize('edit-permissions.permissions-table.activateUserRolesConfirmationFail.toast') : this.localize('edit-permissions.permissions-table.deactivateUserRolesConfirmationFail.toast');
          this.session.toast({ type: 'critical', message });
        }
      }
    }
  }

}

window.customElements.define('edit-permissions', EditPermissions);

